.search__results {
  margin-top: 65px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 75% minmax(25%, 200px);
  max-width: 1072px;
}

.search__results-plays {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  gap: 30px;
}
