.authors__block {
  padding: 0;
  margin: 0 0 60px 0;
  list-style: none;
}

.authors__letter {
  font-weight: 400;
  margin: 0;
  font-size: 40px;
  line-height: 40px;
  padding-bottom: 14px;
}

.authors__names {
  padding: 0;
  margin: 0;
  list-style: none;
}

.authors__name {
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 12px;
  letter-spacing: -0.7px;
}
