.search__input {
  padding: 0 0 0 5px;
  font-size: 18px;
  line-height: 18px;
  width: 382px;
  height: 32px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
}

.search__button {
  padding: 0;
  font-size: 16px;
  line-height: 14px;
  width: 120px;
  height: 32px;
  text-align: left;
  border-radius: 0;
  background-color: transparent;
  border-right: none;
  border-top: none;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
  text-transform: uppercase;
}

.search__arrow-icon {
  display: inline-block;
  margin-right: 8px;
  width: 21px;
  height: 13px;
  background-image: url(../imgs/icons/RightArrow.svg);
  transform: translateY(1px);
}
