@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  font-family: Inter, 'Helvetica Neue', Arial, sans-serif;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #ecebe8;
  padding-top: 189px;
  padding-left: 121px;
  padding-bottom: 100px;
}

.search__header-text {
  margin: 0;
  font-size: 56px;
  font-weight: 400;
  padding-bottom: 25px;
  max-width: 780px;
  letter-spacing: -0.5px;
}

.search__input {
  padding: 0 0 0 5px;
  font-size: 18px;
  line-height: 18px;
  width: 382px;
  height: 32px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
}

.search__button {
  padding: 0;
  font-size: 16px;
  line-height: 14px;
  width: 120px;
  height: 32px;
  text-align: left;
  border-radius: 0;
  background-color: transparent;
  border-right: none;
  border-top: none;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
  text-transform: uppercase;
}

.search__arrow-icon {
  display: inline-block;
  margin-right: 8px;
  width: 21px;
  height: 13px;
  background-image: url(/static/media/RightArrow.4421aea1.svg);
  transform: translateY(1px);
}

.search__results {
  margin-top: 65px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 75% minmax(25%, 200px);
  max-width: 1072px;
}

.search__results-plays {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-gap: 30px;
  gap: 30px;
}

.play {
  width: 240px;
  height: 412px;
  background-color: #;
}

.play__cover {
  margin-bottom: 0;
  padding: 24px 31px 0 24px;
  box-sizing: border-box;
  height: 300px;
  background-color: #b7c09d;
  font-size: 20px;
  font-weight: 400;
}

.play__meta {
  padding-top: 16px;
  max-width: 190px;
}

.play__author {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}

.play__city {
  margin: 4px 0 0;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
}

.play__year {
  margin: 0;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
}

.authors__block {
  padding: 0;
  margin: 0 0 60px 0;
  list-style: none;
}

.authors__letter {
  font-weight: 400;
  margin: 0;
  font-size: 40px;
  line-height: 40px;
  padding-bottom: 14px;
}

.authors__names {
  padding: 0;
  margin: 0;
  list-style: none;
}

.authors__name {
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 12px;
  letter-spacing: -0.7px;
}



