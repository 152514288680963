.play {
  width: 240px;
  height: 412px;
  background-color: #;
}

.play__cover {
  margin-bottom: 0;
  padding: 24px 31px 0 24px;
  box-sizing: border-box;
  height: 300px;
  background-color: #b7c09d;
  font-size: 20px;
  font-weight: 400;
}

.play__meta {
  padding-top: 16px;
  max-width: 190px;
}

.play__author {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}

.play__city {
  margin: 4px 0 0;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
}

.play__year {
  margin: 0;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
}
